.table-legend {
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  margin-bottom: 0.75rem;
  white-space: nowrap;
}

.table-legend {
  .lb-loader-root {
    .table-legend-value-loader {
      width: 3.5rem;
    }
  }
}
